import { MobileOutlined } from '@ant-design/icons'
import React, { useState } from 'react';
import { useSelector } from 'react-redux'
import { PrimaryButton } from 'shared/components';
import { textGroupID } from 'shared/constants'
import { selectIframeGroupId } from 'features/auth/redux/authSelector'
import { selectDiscountInfo } from 'features/search/redux/searchSelector'
import SendModal from 'features/search/components/CouponSend/SendModal'
import TextSendForm from 'features/search/components/CouponSend/TextSendForm'
import EmailSendForm from 'features/search/components/CouponSend/EmailSendForm'
// import { print } from 'utils';

export default () => {
  const [textVisible, setTextVisible] = useState(false)
  const [emailVisible, setEmailVisible] = useState(false)
  const discountInfo = useSelector(selectDiscountInfo)
  const iframeGroupId = useSelector(selectIframeGroupId)

  let messageBody = '';
  let emailBody = '';
  if (discountInfo) {
    const { rxBIN, rxPCN } = discountInfo
    const discountGroupId = iframeGroupId || textGroupID
    messageBody = {
      "phoneNumber": "",
      "RxBin": rxBIN,
      "RxPcn": rxPCN,
      "GroupId": discountGroupId,
      "CardHolderId": discountGroupId
    }
    emailBody = {
      "emailAddress": "",
      "RxBin": rxBIN,
      "RxPcn": rxPCN,
      "GroupId": discountGroupId,
      "CardHolderId": discountGroupId
    }
  }

  return (
    <div className='c-card-screen__card'>
      <SendModal
        visible={textVisible}
        toggleModal={setTextVisible}
      >
        <TextSendForm
          toggleModal={setTextVisible}
          messageBody={messageBody}
          tabIndex="1"
        />
      </SendModal>
      <SendModal
        visible={emailVisible}
        toggleModal={setEmailVisible}
      >
        <EmailSendForm
          toggleModal={setEmailVisible}
          emailBody={emailBody}
        />
      </SendModal>
      <div className='c-coupon-send__buttons'>
        <PrimaryButton
          onClick={() => setTextVisible(true)}
        >
          <MobileOutlined /> Text Card
        </PrimaryButton>
        {/* <PrimaryButton
          onClick={() => setEmailVisible(true)}
        >
          <MailOutlined /> Email Card
        </PrimaryButton>
        <PrimaryButton
          onClick={print}
        >
          <PrinterOutlined /> Print Card
        </PrimaryButton> */}
      </div>
    </div>
  )
}
