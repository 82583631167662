import React, { useState } from 'react';
import { Modal } from 'antd';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { FaPlus, FaMinus, FaXmark } from "react-icons/fa6";
import { useLocation } from 'react-router-dom';

// Define the component with props destructuring
const DiscountCard = ({ width, height, home }) => {
  // Set default width and height
  // const defaultWidth = '465px';
  // const defaultHeight = '261.56px';

  // // Set different width and height for 'home' prop
  // const homeWidth = '560px'; // Example width for home, adjust as needed
  // const homeHeight = '300px'; // Example height for home, adjust as needed

  // // Conditional width and height based on the 'home' prop
  // const finalWidth = home ? homeWidth : width || defaultWidth;
  // const finalHeight = home ? homeHeight : height || defaultHeight;

  const location = useLocation();

  // Adjust the image source based on the 'home' prop
  let imageSrc;
  if (home) {
    imageSrc = '/images/card.png'; // For the homepage
  } else if (location.pathname === '/5566') {
    imageSrc = '/images/prescriptioncard.png'; // Specific URL path '/5566'
  } else {
    imageSrc = '/images/card.png'; // Default or other paths
  }

  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="c-discountcard--top" tabIndex="1">
      <div
          className={`c-discountcard${home ? '-home' : ''}`}
          style={{position: 'relative'}}>
        <div className={`c-discountcard${home ? '-home' : ''}__bg`}/>
        <img
            width={465}
            height={261}
            src={imageSrc}
            alt="Visual of DDN discount prescription card"
            className="c-discountcard-image"
            onClick={showModal}
        />
        <Modal title={null} footer={null} open={isModalOpen} onCancel={handleCancel} className="c-discountcard-image">
          <TransformWrapper
              initialScale={1}
              minScale={1}
              maxScale={2}
              initialPositionY={200}
          >
            {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                <React.Fragment>
                  <div className="tools">
                    <button onClick={() => zoomIn()}><FaPlus/></button>
                    <button onClick={() => zoomOut()}><FaMinus/></button>
                    {/* <button onClick={() => resetTransform()}><FaArrowRotateRight/></button> */}
                    <button onClick={() => handleCancel()}><FaXmark /></button>
                  </div>
                  <TransformComponent>
                    <img src={imageSrc} alt="test" />
                  </TransformComponent>
                </React.Fragment>
            )}
          </TransformWrapper>
        </Modal>
      </div>
    </div>
  );
};

export default DiscountCard;
