import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch, Redirect } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import toast from './utils/toast';
import { Header, Footer } from './layout';
import { Screen, Content } from './shared/components';
import {
  setIframeGroupId,
  setIframeState,
  setIframeContainerBgColor,
} from './features/auth/redux/authActions';
import { selectIsIframe } from './features/auth/redux/authSelector';
import {
  SearchScreen,
  GetDiscountScreen,
  GetCouponScreen,
  ChangelogScreen,
  PrivacyPolicyScreen,
  AccessibilityStatementScreen
} from 'screens'

const App = () => {
  const dispatch = useDispatch()
  const isFrame = useSelector(selectIsIframe)

  useEffect(() => {
    const messageHandler = (e) => {
      // if (e.origin === window.location.origin || !e.data) {
      //   return
      // }
      if (!e.data || !e.data.iframe) {
        return
      }
      const { iframe, groupId, containerBgColor } = e.data
      if (iframe) {
        dispatch(setIframeState(true))
        dispatch(setIframeGroupId(groupId))
      }
      if (containerBgColor) {
        dispatch(setIframeContainerBgColor(containerBgColor))
      }
    }
    window.addEventListener('message', messageHandler)
    return () => window.removeEventListener('message', messageHandler)
  }, [dispatch])

  return (
    <Screen>
      <Header isFrame={isFrame} />
      <Content>
        <Switch>
          <Route exact path='/' component={SearchScreen} />
          <Route exact path='/5566' component={SearchScreen} />
          <Route exact path='/get-discount' component={GetDiscountScreen} />
          <Route exact path='/get-coupon' component={GetCouponScreen} />
          <Route exact path='/changelog' component={ChangelogScreen} />
          <Route exact path='/privacy-policy-terms-of-use' component={PrivacyPolicyScreen} />
          <Route exact path='/accessibility-statement' component={AccessibilityStatementScreen} />
          <Redirect exact from='/prescription-card-signup-form' to='/get-card' />
        </Switch >
      </Content >
      <Footer isFrame={isFrame} />
      <ToastContainer {...toast.options} />
    </Screen >
  )
}

export default App
