import React from 'react'
import { Container } from 'shared/components'
import SearchInput from '../SearchInput'
import HomeLogos from '../HomeLogos'
import { SendDiscountCard } from 'features/getcard/components'
import DiscountCard from 'shared/assets/images/DiscountDrugNetwork';

const HomeSearch = ({ isFrame }) => {
  const renderTopContent = () => {
    if (isFrame) return null
    return (
      <>
        {/* <div className='c-search-form__intro'>
          <span>Prescription Discount Card</span>
        </div>
        <div className='c-search-form__free'>
          Free. No memberships. Up to 80% savings!
        </div> */}
        <DiscountCard width="400px" height="100px" />
        <div className='mt-3'>
          <span className='c-search-input__title mb-5'>
            GET A FREE DIGITAL CARD
          </span>
          <SendDiscountCard />
        </div>
      </>
    )
  }

  return (
    <Container>
        <div className={`c-search-form ${isFrame ? 'c-search-form--iframe' : ''}`}>
            {renderTopContent()}
            <SearchInput/>
            <HomeLogos isFrame={isFrame}/>
            <div className='c-search-form__legal'>
                <small>* Discount Drug Network is not sponsored or affiliated with any of third-party brands identified.
                    Trademarks, brands, logos, and copyrights are the property of their respective owners.</small>
            </div>
        </div>
    </Container>
  )
}

export default HomeSearch
